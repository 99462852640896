<template>
  <div
    ref="chart-container"
    class="chart-container"
  >
    <svg ref="my-chart" />
  </div>
</template>
<script>
import * as d3 from "d3";
// const  margin = {top: 10, right: 30, bottom: 20, left: 50};
const margin = {"top": 20, "right": 10, "bottom": 20, "left": 60 };

export default {
  props:["data"],
  data(){
    return{
      chartData:[]
    }
  },

  computed: {

  },
  watch:{
    data(){
      console.log("change data")
      this.chartData = this.data;
      this.drawChart();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.chartData = this.data;
    this.drawChart();
    window.addEventListener('resize', this.handleResize);



  },
  methods:{
    handleResize(){
      console.log('resize');
      this.drawChart();
    },
    drawChart(){
      const width = this.$refs["chart-container"].clientWidth;
      const height = this.$refs["chart-container"].clientHeight;

      d3.select(this.$refs["my-chart"]).select('svg').remove();

      let svg = d3.select(this.$refs["my-chart"])
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        // .append("g")


      const color_key_value ={'UNKOWN':'black','IDLE':'gray','RUN':'blue','PM':'red','BM':'yellow'}
      const font_colors =['white','white']


      let params = {};
      for (let i = 0; i < this.chartData.length; i++) {
        params[this.chartData[i].name] = i;
      }
      let newData = [];
      let dates = [];
      this.chartData.map(d =>
      { d.data.map(sub=>
      {
        sub.start = new Date(sub.start);
        sub.end = new Date(sub.end);
        sub.name = d.name;
        newData.push(sub);
        dates.push(sub.start);
        dates.push(sub.end);
      }) ;
      return d;
      })
      // this.chartData = newData;
      const rectHeight = 20;

      var xScale = d3.scaleUtc(d3.extent(dates,(d)=>{return d}),[ margin.left,width - margin.right]);
      // console.log(xScale(data[0][0]))
      var yMax = Object.keys(params).length * rectHeight;
      var yScale = d3.scaleLinear()
        .domain([0, yMax])
        .range([margin.top, height - margin.bottom]);

      var tooltip;

      let formatTime = d3.timeFormat("%Y-%m-%d %H:%M")
      // bars
      var rect = svg.selectAll('rect')
        .data(newData)
        .enter().append('rect')
        .attr('x', function(d, i){
          return  xScale(d.start) })
        .attr('y', function(d,i){
          console.log(params[d.name]);
          return  yScale(params[d.name] * rectHeight + 5 )})
        .attr('height', yScale(rectHeight-10) - margin.bottom)
        .attr('width', function(d){
          return xScale(d.end)-xScale(d.start)})
        .attr('fill', function(d){
          return color_key_value[d.type]})
        .attr('margin', 0)

        .on('mouseover', (e, d) => {
          tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("position","absolute")
            .style("opacity", 0)
            .style("border","solid 1px gray" )
            .style("padding","5px" )
          const x = e.pageX+10;
          const y = e.pageY+10;
          console.log("###############",d)

          tooltip.transition().duration(200).style("opacity", .9).style("background",color_key_value[d.type]).style("color",'white');
          tooltip.html(` Type:  ${d.type}</br>Start:  ${formatTime(d.start)}</br>End:   ${formatTime(d.end)}`).style("left", (x) + "px").style("top", (y) + "px");
        })
        .on('mousemove', (e, d) => {
          const x = e.pageX+10;
          const y = e.pageY+10;

          tooltip.transition().duration(200).style("opacity", .9).style("background",color_key_value[d.type]).style("color",'white');
          tooltip.html(` Type:  ${d.type}</br>Start:  ${formatTime(d.start)}</br>End:   ${formatTime(d.end)}`).style("left", (x) + "px").style("top", (y) + "px");

        })
        .on('mouseout', e => {
          console.log('out')
          tooltip.transition() .duration(500) .style("opacity", 0);
          tooltip.remove();
        });


      var xAxis = d3.axisBottom(xScale).ticks(width / 80).tickSizeOuter(0);

      let tickValue =[];
      Object.keys(params).map(d=>{
        tickValue.push( params[d]*rectHeight+rectHeight/2)
      })
      var yAxis = d3.axisLeft()
        .scale(yScale)
        .tickValues(tickValue )
        .tickFormat((d,i)=>Object.keys(params)[i])

      // .tickFormat(d3.format('d'));
      svg.append('g')
        .attr('transform', 'translate(' + [0, height - margin.bottom] + ')')
        .call(xAxis)
        .style('font-size','12px')

      svg.append('g')
        .attr('transform', 'translate(' + [margin.left, 0] + ')')
        .call(yAxis)
        .style('font-size','12px')


      var legendRectSize = 12;                                  // NEW
      var legendSpacing = 4;

      let colors = [];
      Object.keys(color_key_value).map(c=>{colors.push(color_key_value[c])})


      var color = d3.scaleOrdinal()
        .domain(Object.keys(color_key_value))
        .range(colors);

      var svgLegned4 = svg.append("svg")
      // .attr("width", width)
      // .attr("height", height - 50)

      var dataL = 0;
      var offset = 80;

      var legend = svgLegned4.selectAll('.legend')
        .data(Object.keys(color_key_value))
        .enter().append('g')
        .attr("class", "legend")
        .attr("transform", function (d, i) {
          if (i === 0) {
            dataL = d.length + offset
            return "translate(0,0)"
          } else {
            var newdataL = dataL
            dataL +=  d.length + offset
            return "translate(" + (newdataL) + ",0)"
          }
        })
      legend.append('rect')
        .attr("x", margin.left)
        .attr("y", 5)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", function (d, i) {
          return color(i)
        })

      legend.append('text')
        .attr("x", margin.left+15)
        .attr("y", 15)
        //.attr("dy", ".35em")
        .text(function (d, i) {
          return d
        })
        .attr("class", "textselected")
        .style("text-anchor", "start")
        .style("font-size", 15)
    }
  }
}
</script>

<style scoped>
  .chart-container{
    width:100%;
    height:calc(100% - 10px);
  }
  svg{
    width: 100%;
    height: 100%;
  }
</style>
