<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <eqp-param-health class="widget" />
      </v-col>
      <v-col cols="4">
        <eqp-alarm class="widget" />
      </v-col>
      <v-col cols="4">
        <param-trend class="widget" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <param-candle class="widget" />
      </v-col>
      <v-col cols="4">
        <multi-param-trend class="widget" />
      </v-col>
      <v-col cols="4">
        <fast-param-trend class="widget" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <bar-chart class="widget" />
      </v-col>
      <v-col cols="4">
        <fill-stack class="widget" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EqpParamHealth from "@/views/chart/EqpParamHealth";
import EqpAlarm from "@/views/chart/EqpAlarm";
import ParamTrend from "@/views/chart/ParamTrend";
import ParamCandle from "@/views/chart/ParamCandle";
import MultiParamTrend from "@/views/chart/MultiParamTrend";
import FastParamTrend from "@/views/chart/FastParamTrend";
import FillStack from "@/views/chart/FillStack";
import BarChart from "@/views/chart/BarChart";
export default {
  name: "Plotly",
  components:{
    ParamTrend,
    EqpParamHealth,
    EqpAlarm,
    ParamCandle,
    MultiParamTrend,
    FastParamTrend,
    FillStack,
    BarChart
  }
}
</script>

<style scoped>
.widget{
  border:solid 1px gray;
  height:350px;
}
</style>
