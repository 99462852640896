<template>
  <v-container class="eqp-alarm">
    <div class="line-container">
      <div v-for="item in items" :key="item" class="ma-2 line-name">
        {{ item }}
      </div>
    </div>
    <div class="chart">
      <Plotly :data="data" :layout="layout" :display-mode-bar="false" />
    </div>
  </v-container>
</template>

<script>
import { Plotly } from "vue-plotly";
export default {
  name: "EqpParameterHealth",
  components: {
    Plotly,
  },
  data: function () {
    return {
      items: ["Line1", "Line2"],
      data: [
        {
          y: ["Liam", "Sophie", "Jacob", "Mia", "William", "Olivia"],
          x: [8.0, 8.0, 12.0, 12.0, 13.0, 20.0],
          type: "bar",
          text: [
            "4.17 below the mean",
            "4.17 below the mean",
            "0.17 below the mean",
            "0.17 below the mean",
            "0.83 above the mean",
            "7.83 above the mean",
          ],
          marker: {
            color: ["red", "green", "blue", "yellow", "pink", "gray"],
          },
          orientation: "h",
        },
      ],
      layout: {
        autosize: true,
        // automargin:true,
        // showlegend:false,
        // title: 'Number of Graphs Made this Week',
        font: {
          family: "Raleway, sans-serif",
        },
        xaxis: {
          // tickangle: -45
        },
        yaxis: {
          zeroline: false,
          gridwidth: 2,
        },
        bargap: 0.05,
        margin: {
          l: 100,
          r: 15,
          b: 20,
          t: 15,
          pad: 0,
        },
      },
    };
  },
  created() {},
  mounted() {},
};
</script>

<style  lang="scss">
.eqp-alarm {
  .line-container {
    display: flex;
    .line-name {
      border-radius: 5px;
      background: cadetblue;
      padding: 5px;
    }
  }

  .chart {
    height: calc(100% - 50px);
    overflow: hidden;
    width: 100%;
  }
}
</style>
