<template>
  <div
    style="width:100%;height:100%;"
    class="eqp-process-status"
  >
    <multi-stack-chart :data="data" />
  </div>
</template>

<script>
import Widget from "@/common-lib/widgets/Widget";
import MultiStackChart from "@/components/MultiStackChart";

export default {
  components: {
    MultiStackChart
  },
  mixins:[Widget],
  data() {
    return {
      receiveData:"",
      data : [
        {name:'param1',data:[
          {type:'IDLE' ,start:'2022-01-01 01:00:00',end:'2022-01-01 02:00:00'},
          {type:'RUN'  ,start:'2022-01-01 02:00:00',end:'2022-01-01 04:00:00'},
          {type:'PM',start:'2022-01-01 04:00:00',end:'2022-01-01 10:00:00'},
          {type:'IDLE' ,start:'2022-01-01 10:00:00',end:'2022-01-01 14:00:00'},
          {type:'RUN'  ,start:'2022-01-01 14:00:00',end:'2022-01-01 20:00:00'},
        ]},
        {name:'param2',data:[
          {type:'RUN' ,start:'2022-01-01 01:00:00',end:'2022-01-01 10:00:00'},
          {type:'IDLE',start:'2022-01-01 10:00:00',end:'2022-01-01 12:00:00'},
          {type:'RUN' ,start:'2022-01-01 12:00:00',end:'2022-01-01 20:00:00'},
        ]},
        {name:'param3',data:[
          {type:'IDLE' ,start:'2022-01-01 01:00:00',end:'2022-01-01 02:00:00'},
          {type:'RUN'  ,start:'2022-01-01 02:00:00',end:'2022-01-01 03:00:00'},
          {type:'PM',start:'2022-01-01 03:00:00',end:'2022-01-01 04:00:00'},
          {type:'IDLE' ,start:'2022-01-01 04:00:00',end:'2022-01-01 14:00:00'},
          {type:'RUN'  ,start:'2022-01-01 14:00:00',end:'2022-01-01 20:00:00'},
        ]},
        {name:'param4',data:[
          {type:'RUN' ,start:'2022-01-01 01:00:00',end:'2022-01-01 02:00:00'},
          {type:'IDLE'  ,start:'2022-01-01 02:00:00',end:'2022-01-01 03:00:00'},
          {type:'RUN',start:'2022-01-01 03:00:00',end:'2022-01-01 04:00:00'},
          {type:'IDLE' ,start:'2022-01-01 04:00:00',end:'2022-01-01 06:00:00'},
          {type:'PM' ,start:'2022-01-01 06:00:00',end:'2022-01-01 14:00:00'},
          {type:'RUN'  ,start:'2022-01-01 14:00:00',end:'2022-01-01 20:00:00'},
        ]},

      ]
    };
  },

  methods:{
    // 아래 함수만 구현 하면 됨
    // Override
    receiveConfig(config){

    },
    receiveSyncData(data){
      console.log("receiveSyncData",data);
      this.receiveData = data;
    },
    changeData(){
      let data = {date:new Date(),message:"my sync data"};
      this.sendSyncData(data);
    }
  }
};
</script>

<style  lang="scss">
.eqp-process-status {
  .line-container {
    display: flex;
    .line-name{
      border-radius: 5px;
      background: cadetblue;
      padding: 0px 5px;
      color:white;
      height: 24px;

    }
  }

  .chart {
    height: calc(100% - 50px);
    overflow: hidden;
    width: 100%;
  }
}
</style>
