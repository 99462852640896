<template>
  <v-container class="param-trend">
    <div class="chart">
      <Plotly :data="data" :layout="layout" :display-mode-bar="false" />
    </div>
  </v-container>
</template>

<script>
import { Plotly } from "vue-plotly";
export default {
  name: "MultiParamTrend",
  components: {
    Plotly,
  },
  data: function () {
    return {
      spec: {
        alarm: {
          highLimit: { low: -1, high: -3 },
          lowLimit: { high: -18, low: -16 },
        },
        warning: {
          highLimit: { high: -16, low: -14 },
          lowLimit: { low: 1, high: -1 },
        },
      },
      data: [
        {
          x: [
            "2015-02-01",
            "2015-02-02",
            "2015-02-03",
            "2015-02-04",
            "2015-02-05",
            "2015-02-06",
            "2015-02-07",
            "2015-02-08",
            "2015-02-09",
            "2015-02-10",
            "2015-02-11",
            "2015-02-12",
            "2015-02-13",
            "2015-02-14",
            "2015-02-15",
            "2015-02-16",
            "2015-02-17",
            "2015-02-18",
            "2015-02-19",
            "2015-02-20",
            "2015-02-21",
            "2015-02-22",
            "2015-02-23",
            "2015-02-24",
            "2015-02-25",
            "2015-02-26",
            "2015-02-27",
            "2015-02-28",
          ],
          y: [
            100, 100, 100, 100, 100, 100, 100, 100, 80, 80, 80, 80, 70, 70, 80,
            80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 70, 65, 100,
          ],

          type: "scatter",
          mode: "lines+markers",
          name: "temperature",
          line: { shape: "hv" },
        },
        {
          x: [
            "2015-02-01",
            "2015-02-02",
            "2015-02-03",
            "2015-02-04",
            "2015-02-05",
            "2015-02-06",
            "2015-02-07",
            "2015-02-08",
            "2015-02-09",
            "2015-02-10",
            "2015-02-11",
            "2015-02-12",
            "2015-02-13",
            "2015-02-14",
            "2015-02-15",
            "2015-02-16",
            "2015-02-17",
            "2015-02-18",
            "2015-02-19",
            "2015-02-20",
            "2015-02-21",
            "2015-02-22",
            "2015-02-23",
            "2015-02-24",
            "2015-02-25",
            "2015-02-26",
            "2015-02-27",
            "2015-02-28",
          ],
          y: [
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1,
          ],
          marker: {
            color: [
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
            ],
          },
          type: "bar",
          name: "status",
          xaxis: "x2",
          yaxis: "y2",
        },
      ],
      layout: {
        autosize: true,
        margin: {
          l: 50,
          r: 15,
          b: 15,
          t: 15,
          pad: 0,
        },
        showlegend: false,

        // to highlight the timestamp we use shapes and create a rectangular

        width: 500,
        // grid:{rows:2,columns:1, pattern:'independent'},
        yaxis: { domain: [0.25, 1], range: [0, 110] },
        xaxis2: { anchor: "x2", visible: false },
        yaxis2: {
          anchor: "y2",
          domain: [0, 0.125],
          visible: false,
          range: [0, 5],
        },
      },
    };
  },
  created() {},
  mounted() {},
};
</script>

<style  lang="scss">
.param-trend {
  .chart {
    //height: calc(100% - 50px);
    height: 100%;
    width: 100%;
  }
}
</style>
