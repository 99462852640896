<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <eqp-param-health class="widget" />
      </v-col>
      <v-col cols="4">
        <eqp-alarm class="widget" />
      </v-col>
      <v-col cols="4">
        <param-trend class="widget" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <param-candle class="widget" />
      </v-col>
      <v-col cols="4">
        <multi-param-trend class="widget" />
      </v-col>
      <v-col cols="4">
        <fast-param-trend class="widget" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <bar-chart class="widget" />
      </v-col>
      <v-col cols="4">
        <fill-stack class="widget" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EqpParamHealth from "./eqpParamHealth/EqpParamHealthWidget.vue";
import EqpAlarm from "./eqpAlarm/EqpAlarmWidget.vue";
import ParamTrend from "./eqpParamTrend/EqpParamTrendWidget.vue";
import ParamCandle from "./eqpParamTrendBoxPlot/EqpParamTrendBoxPlotWidget.vue";
import MultiParamTrend from "./eqpParamTrend/EqpParamTrendWidget.vue";
import FastParamTrend from "./eqpParamTrend/EqpParamTrendWidget.vue";
// import FillStack from "./";
import BarChart from "./eqpProcessStatus/EqpProcessStatusWidget.vue";
export default {
  name: "Plotly",
  components: {
    ParamTrend,
    EqpParamHealth,
    EqpAlarm,
    ParamCandle,
    MultiParamTrend,
    FastParamTrend,
    // FillStack,
    BarChart,
  },
};
</script>

<style scoped>
.widget {
  border: solid 1px gray;
  height: 350px;
}
</style>
