<template>
  <div style="width: 100%; height: 100%">
    <div class="head">
      {{ title }}
    </div>
    <div class="score-chart">
      <div
        class="score-container"
        :class="
          score >= 90
            ? 'score-green'
            : score >= 80
            ? 'score-yellow'
            : 'score-red'
        "
      >
        <label>{{ score }}%</label>
        <label>{{ $t("평균 건강 지수") }}</label>
      </div>
      <div class="chart">
        <Plotly
          class="plotly"
          :data="data"
          :layout="layout"
          :display-mode-bar="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Plotly } from "vue-plotly";
import Widget from "@/common-lib/widgets/Widget";

export default {
  components: {
    Plotly,
  },
  mixins: [Widget],
  data() {
    return {
      receiveData: "",
      title: "Facillities_1",
      score: 12,
      data: [
        {
          type: "scatterpolar",
          r: [39, 28, 8, 7, 28, 39],
          theta: [
            "Param_1",
            "Param_2",
            "Param_3",
            "Param_4",
            "Param_5",
            "Param_1",
          ],
          fill: "toself",
          name: "Group A",
        },
        {
          type: "scatterpolar",
          r: [1.5, 10, 39, 31, 15, 1.5],
          theta: [
            "Param_1",
            "Param_2",
            "Param_3",
            "Param_4",
            "Param_5",
            "Param_1",
          ],
          fill: "toself",
          name: "Group B",
        },
      ],
      layout: {
        autosize: true,
        showlegend: false,
        margin: {
          l: 15,
          r: 15,
          b: 18,
          t: 15,
          pad: 0,
        },
        polar: {
          radialaxis: {
            visible: true,
            range: [0, 50],
          },
        },
      },
    };
  },

  methods: {
    // 아래 함수만 구현 하면 됨
    // Override
    receiveConfig(config) {
      let a = Math.random() * 50;
      let b = Math.random() * 50;
      let c = Math.random() * 50;
      let d = Math.random() * 50;
      let e = Math.random() * 50;
      this.data[0].r = [a, b, c, d, e, a];

      a = Math.random() * 50;
      b = Math.random() * 50;
      c = Math.random() * 50;
      d = Math.random() * 50;
      e = Math.random() * 50;
      this.data[1].r = [a, b, c, d, e, a];
      this.data = Object.assign([], this.data);
      this.score = (Math.random() * (100 - 40) + 40).toFixed(0);
    },
    receiveSyncData(data) {
      console.log("receiveSyncData", data);
      this.receiveData = data;
    },
    changeData() {
      let data = { date: new Date(), message: "my sync data" };
      this.sendSyncData(data);
    },
  },
};
</script>

<style  lang="scss">
.head {
  min-width: 150px;
  width: 50%;
  background: cadetblue;
  border-radius: 5px;
  text-align: center;
  color: white;
  height: 24px;
}
.score-chart {
  height: calc(100% - 24px);
}

.score-container {
  width: 140px;
  //height: 50px;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  label:first-child {
    font-size: 30px;
  }
}
.chart {
  width: calc(100% - 100px);
  overflow: hidden;
  height: 100%;
}
.score-chart {
  display: flex;
  flex-direction: row;
  align-items: center;
  .score-green {
    background: green;
    label {
      color: black;
    }
  }
  .score-yellow {
    background: yellow;
    label {
      color: black;
    }
  }
  .score-red {
    background: red;
    label {
      color: white;
    }
  }
  .plotly {
    width: 100%;
    height: 100%;
  }
}
</style>
