<template>
  <div
    ref="chart-container"
    class="chart-container"
  >
    <svg ref="my-chart" />
  </div>
</template>
<script>
import * as d3 from "d3";
// const  margin = {top: 10, right: 30, bottom: 20, left: 50};
const margin = {"top": 20, "right": 10, "bottom": 20, "left": 60 };

export default {
  data() {
    return {

    };
  },
  computed: {

  },
  mounted() {

    const width = 500;
    const height = 300;

    let svg = d3.select(this.$refs["my-chart"])
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      // .attr("transform",
      //   "translate(" + margin.left + "," + margin.top + ")");

    // Parse the Data

    let data = [
      {name:'param1',data:[
        {type:'idle' ,start:'2022-01-01 01:00:00',end:'2022-01-01 02:00:00'},
        {type:'run'  ,start:'2022-01-01 02:00:00',end:'2022-01-01 04:00:00'},
        {type:'maint',start:'2022-01-01 04:00:00',end:'2022-01-01 10:00:00'},
        {type:'idle' ,start:'2022-01-01 10:00:00',end:'2022-01-01 14:00:00'},
        {type:'run'  ,start:'2022-01-01 14:00:00',end:'2022-01-01 20:00:00'},
      ]},
      {name:'param2',data:[
        {type:'run' ,start:'2022-01-01 01:00:00',end:'2022-01-01 10:00:00'},
        {type:'idle',start:'2022-01-01 10:00:00',end:'2022-01-01 12:00:00'},
        {type:'run' ,start:'2022-01-01 12:00:00',end:'2022-01-01 20:00:00'},
      ]},
      {name:'param3',data:[
        {type:'idle' ,start:'2022-01-01 01:00:00',end:'2022-01-01 02:00:00'},
        {type:'run'  ,start:'2022-01-01 02:00:00',end:'2022-01-01 03:00:00'},
        {type:'maint',start:'2022-01-01 03:00:00',end:'2022-01-01 04:00:00'},
        {type:'idle' ,start:'2022-01-01 04:00:00',end:'2022-01-01 14:00:00'},
        {type:'run'  ,start:'2022-01-01 14:00:00',end:'2022-01-01 20:00:00'},
      ]},

    ];

    const color_key_value ={'idle':'gray','run':'blue','maint':'red'}
    const font_colors =['white','white']

    // data
    // var data = [['2022-01-01', "red"], ['2022-01-02', "teal"], ['2022-01-05', "yellow"],
    //   ['2022-01-06', "purple"], ['2022-01-07', "green"]];

    // data = data.map(d=>{d[0] = new Date(d[0]);return d;})

    let params = {};
    for (let i = 0; i < data.length; i++) {
      params[data[i].name] = i;
    }
    let newData = [];
    let dates = [];
    data.map(d =>
    { d.data = d.data.map(sub=>
    {
      sub.start = new Date(sub.start);
      sub.end = new Date(sub.end);
      sub.name = d.name;
      newData.push(sub);
      dates.push(sub.start);
      dates.push(sub.end);
    }) ;
    return d;
    })
    data = newData;
    const rectHeight = 20;

    // scales
    // var xMax = d3.max(data, function(d){return d[0]});
    // var xScale = d3.scaleLinear()
    //   .domain([0, xMax])
    //   .range([width - margin.right, margin.left]);
    // var xMax = d3.max(data, function(d){return d[0]});
    // var xScale = d3.scaleUtc(d3.extent(data,(d)=>{return d[0]}),[ margin.left,width - margin.right]);
    var xScale = d3.scaleUtc(d3.extent(dates,(d)=>{return d}),[ margin.left,width - margin.right]);
    console.log(xScale(data[0][0]))
    var yMax = Object.keys(params).length * rectHeight;
    var yScale = d3.scaleLinear()
      .domain([0, yMax])
      .range([margin.top, height - margin.bottom]);


    // svg element
    // var svg = d3.select('svg');



    var tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("position","absolute")
      .style("opacity", 0)
      .style("border","solid 1px gray" )
      .style("padding","5px" )
      // .style("border-radius","5px" )
      // .style("background","white" )
      // .style("color","black" )



    let formatTime = d3.timeFormat("%Y-%m-%d %H:%M")
    // bars
    var rect = svg.selectAll('rect')
      .data(data)
      .enter().append('rect')
      .attr('x', function(d, i){
        return  xScale(d.start) })
      .attr('y', function(d,i){
        console.log(params[d.name]);
        return  yScale(params[d.name] * rectHeight + 5 )})
      .attr('height', yScale(rectHeight-10) - margin.bottom)
      .attr('width', function(d){
        return xScale(d.end)-xScale(d.start)})
      .attr('fill', function(d){
        return color_key_value[d.type]})
      .attr('margin', 0)

      .on('mouseover', (e, d) => {
        const x = e.pageX+10;
        const y = e.pageY+10;
        console.log("###############",d)

        tooltip.transition().duration(200).style("opacity", .9).style("background",color_key_value[d.type]).style("color",'white');
        tooltip.html(` Type:  ${d.type}</br>Start:  ${formatTime(d.start)}</br>End:   ${formatTime(d.end)}`).style("left", (x) + "px").style("top", (y) + "px");
      })
      .on('mousemove', (e, d) => {
        const x = e.pageX+10;
        const y = e.pageY+10;

        tooltip.transition().duration(200).style("opacity", .9).style("background",color_key_value[d.type]).style("color",'white');
        tooltip.html(` Type:  ${d.type}</br>Start:  ${formatTime(d.start)}</br>End:   ${formatTime(d.end)}`).style("left", (x) + "px").style("top", (y) + "px");

      })
      .on('mouseout', e => {
        console.log('out')
        tooltip.transition() .duration(500) .style("opacity", 0);
      });




    // axes
    // var xAxis = d3.axisBottom()
    //   .scale(xScale)
    //   .tickFormat(d3.format('d'));
    var xAxis = d3.axisBottom(xScale).ticks(width / 80).tickSizeOuter(0);

    let tickValue =[];
    Object.keys(params).map(d=>{
      tickValue.push( params[d]*rectHeight+rectHeight/2)
    })
    var yAxis = d3.axisLeft()
      .scale(yScale)
      .tickValues(tickValue )
      .tickFormat((d,i)=>Object.keys(params)[i])

    // .tickFormat(d3.format('d'));
    svg.append('g')
      .attr('transform', 'translate(' + [0, height - margin.bottom] + ')')
      .call(xAxis);
    svg.append('g')
      .attr('transform', 'translate(' + [margin.left, 0] + ')')
      .call(yAxis);


















    var legendRectSize = 12;                                  // NEW
    var legendSpacing = 4;

    let colors = [];
    Object.keys(color_key_value).map(c=>{colors.push(color_key_value[c])})


    var keys = ["Mister A", "Brigitte", "Eleonore", "Another friend", "Batman"]

    var color = d3.scaleOrdinal()
      .domain(Object.keys(color_key_value))
      .range(colors);

    var svgLegned4 = svg.append("svg")
    // .attr("width", width)
    // .attr("height", height - 50)

    var dataL = 0;
    var offset = 80;

    var legend = svgLegned4.selectAll('.legend')
      .data(Object.keys(color_key_value))
      .enter().append('g')
      .attr("class", "legend")
      .attr("transform", function (d, i) {
        if (i === 0) {
          dataL = d.length + offset
          return "translate(0,0)"
        } else {
          var newdataL = dataL
          dataL +=  d.length + offset
          return "translate(" + (newdataL) + ",0)"
        }
      })
    legend.append('rect')
      .attr("x", margin.left+10)
      .attr("y", 5)
      .attr("width", 10)
      .attr("height", 10)
      .style("fill", function (d, i) {
        return color(i)
      })

    legend.append('text')
      .attr("x", margin.left+10+20)
      .attr("y", 15)
      //.attr("dy", ".35em")
      .text(function (d, i) {
        return d
      })
      .attr("class", "textselected")
      .style("text-anchor", "start")
      .style("font-size", 15)

  },
}
</script>

<style scoped>
  .chart-container{
    width:100%;
    height:100%;
  }
  svg{
    width: 100%;
    height: 100%;
  }
</style>
