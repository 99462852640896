<template>
  <v-container class="param-trend">
    <div class="chart">
      <Plotly :data="data" :layout="layout" :display-mode-bar="false" />
    </div>
  </v-container>
</template>

<script>
import { Plotly } from "vue-plotly";

export default {
  name: "ParamTrend",
  components: {
    Plotly,
  },
  data: function () {
    return {
      spec: {
        alarm: {
          highLimit: { low: -1, high: -3 },
          lowLimit: { high: -18, low: -16 },
        },
        warning: {
          highLimit: { high: -16, low: -14 },
          lowLimit: { low: 1, high: -1 },
        },
      },
      data: [
        {
          x: [
            "2015-02-01",
            "2015-02-02",
            "2015-02-03",
            "2015-02-04",
            "2015-02-05",
            "2015-02-06",
            "2015-02-07",
            "2015-02-08",
            "2015-02-09",
            "2015-02-10",
            "2015-02-11",
            "2015-02-12",
            "2015-02-13",
            "2015-02-14",
            "2015-02-15",
            "2015-02-16",
            "2015-02-17",
            "2015-02-18",
            "2015-02-19",
            "2015-02-20",
            "2015-02-21",
            "2015-02-22",
            "2015-02-23",
            "2015-02-24",
            "2015-02-25",
            "2015-02-26",
            "2015-02-27",
            "2015-02-28",
          ],
          y: [
            -14, -17, -8, -4, -7, -10, -12, -14, -12, -7, -11, -7, -18, -14,
            -14, -16, -13, -7, -8, -14, -8, -3, -9, -9, -4, -13, -9, -6,
          ],
          mode: "line",
          name: "temperature",
        },
      ],
      layout: {
        autosize: true,
        margin: {
          l: 50,
          r: 15,
          b: 15,
          t: 15,
          pad: 0,
        },
        // to highlight the timestamp we use shapes and create a rectangular

        shapes: [
          // 1st highlight during Feb 4 - Feb 6
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.8,
            y1: 1,
            x0: 0,
            x1: 1,
            fillcolor: "red",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.2,
            y1: 0,
            x0: 0,
            x1: 1,
            fillcolor: "red",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.6,
            y1: 0.8,
            x0: 0,
            x1: 1,
            fillcolor: "yellow",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.4,
            y1: 0.2,
            x0: 0,
            x1: 1,
            fillcolor: "yellow",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
        ],
        // height: 500,
        width: 500,
      },
    };
  },
  created() {},
  mounted() {},
};
</script>

<style  lang="scss">
.param-trend {
  .chart {
    //height: calc(100% - 50px);
    height: 100%;
    width: 100%;
  }
}
</style>
