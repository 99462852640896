<template>
  <div class="param-trend-boxplot">
    <div class="chart">
      <Plotly
        :id="id"
        ref="myChart"
        class="plotly"
        :data="data"
        :display-mode-bar="false"
        :layout="layout"
      />
    </div>
  </div>
</template>

<script>
import { Plotly } from "vue-plotly";
import Widget from "@/common-lib/widgets/Widget";
import { uuid } from "vue-uuid";
import FdcService from "@/services/FdcService";

export default {
  components: {
    Plotly,
  },
  mixins: [Widget],
  data() {
    return {
      receiveData: "",
      id: uuid.v4(),
      spec: {
        alarm: {
          highLimit: { low: -1, high: -3 },
          lowLimit: { high: -18, low: -16 },
        },
        warning: {
          highLimit: { high: -16, low: -14 },
          lowLimit: { low: 1, high: -1 },
        },
      },
      x: [
        "day 1",
        "day 1",
        "day 1",
        "day 1",
        "day 1",
        "day 1",
        "day 2",
        "day 2",
        "day 2",
        "day 2",
        "day 2",
        "day 2",
      ],
      data: [
        {
          y: [0.2, 0.2, 0.6, 1.0, 0.5, 0.4, 0.2, 0.7, 0.9, 0.1, 0.5, 0.3],
          x: this.x,
          name: "15H",
          marker: { color: "#3D9970" },
          type: "box",
        },

        {
          y: [0.6, 0.7, 0.3, 0.6, 0.0, 0.5, 0.7, 0.9, 0.5, 0.8, 0.7, 0.2],
          x: this.x,
          name: "16H",
          marker: { color: "#FF4136" },
          type: "box",
        },
        {
          y: [0.1, 0.3, 0.1, 0.9, 0.6, 0.6, 0.9, 1.0, 0.3, 0.6, 0.8, 0.5],
          x: this.x,
          name: "17H",
          marker: { color: "#FF851B" },
          type: "box",
        },
        {
          y: [0.2, 0.2, 0.6, 1.0, 0.5, 0.4, 0.2, 0.7, 0.9, 0.1, 0.5, 0.3],
          x: this.x,
          name: "18H",
          marker: { color: "#3D9970" },
          type: "box",
        },

        {
          y: [0.6, 0.7, 0.3, 0.6, 0.0, 0.5, 0.7, 0.9, 0.5, 0.8, 0.7, 0.2],
          x: this.x,
          name: "19H",
          marker: { color: "#FF4136" },
          type: "box",
        },
        {
          y: [0.1, 0.3, 0.1, 0.9, 0.6, 0.6, 0.9, 1.0, 0.3, 0.6, 0.8, 0.5],
          x: this.x,
          name: "20H",
          marker: { color: "#FF851B" },
          type: "box",
        },
      ],
      layout: {
        autosize: true,
        margin: {
          l: 30,
          r: 10,
          b: 50,
          t: 15,
          pad: 0,
        },
        // to highlight the timestamp we use shapes and create a rectangular

        shapes: [
          // 1st highlight during Feb 4 - Feb 6
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.8,
            y1: 1,
            x0: 0,
            x1: 1,
            fillcolor: "red",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.2,
            y1: 0,
            x0: 0,
            x1: 1,
            fillcolor: "red",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.6,
            y1: 0.8,
            x0: 0,
            x1: 1,
            fillcolor: "yellow",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.4,
            y1: 0.2,
            x0: 0,
            x1: 1,
            fillcolor: "yellow",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
        ],

        showlegend: false,

        boxmode: "group",
      },
    };
  },
  mounted() {},
  methods: {
    // 아래 함수만 구현 하면 됨
    // Override
    receiveConfig(config) {},
    receiveSyncData(data) {
      console.log("receiveSyncData", data);
      this.receiveData = data;
    },
    changeData() {
      let data = { date: new Date(), message: "my sync data" };
      this.sendSyncData(data);
    },
  },
};
</script>

<style lang="scss">
.param-trend-boxplot {
  width: 100%;
  height: 100%;

  .chart {
    //height: calc(100% - 50px);
    height: 100%;
    width: 100%;
  }
  .plotly {
    width: 100%;
    height: 100%;
  }
}
</style>
