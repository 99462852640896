<template>
  <div style="width: 100%; height: 100%" class="eqp-alarm">
    <div class="line-container">
      <div v-for="item in items" :key="item" class="ma-2 line-name">
        {{ item }}
      </div>
    </div>
    <div class="chart">
      <Plotly
        class="plotly"
        :data="data"
        :layout="layout"
        :display-mode-bar="false"
      />
    </div>
  </div>
</template>

<script>
import { Plotly } from "vue-plotly";
import Widget from "@/common-lib/widgets/Widget";

export default {
  components: {
    Plotly,
  },
  mixins: [Widget],
  data() {
    return {
      receiveData: "",
      items: ["Line_1", "Line_2"],
      data: [
        {
          y: [
            "Facilities_6",
            "Facilities_5",
            "Facilities_4",
            "Facilities_3",
            "Facilities_2",
            "Facilities_1",
          ],
          x: [8.0, 8.0, 12.0, 12.0, 13.0, 20.0],
          type: "bar",
          text: [8.0, 8.0, 12.0, 12.0, 13.0, 20.0],
          marker: {
            color: ["red", "green", "blue", "yellow", "pink", "gray"],
          },
          orientation: "h",
        },
      ],
      layout: {
        autosize: true,
        // automargin:true,
        // showlegend:false,
        // title: 'Number of Graphs Made this Week',
        font: {
          family: "Raleway, sans-serif",
        },
        xaxis: {
          // tickangle: -45
          range: [0, 20],
        },
        yaxis: {
          zeroline: false,
          gridwidth: 2,
          ticks: "outside",
          ticklen: 9,
        },
        bargap: 0.05,
        margin: {
          l: 75,
          r: 15,
          b: 15,
          t: 5,
          pad: 0,
        },
      },
    };
  },

  methods: {
    // 아래 함수만 구현 하면 됨
    // Override
    receiveConfig(config) {
      const a = Math.ceil(Math.random() * 20);
      const b = Math.ceil(Math.random() * 20);
      const c = Math.ceil(Math.random() * 20);
      const d = Math.ceil(Math.random() * 20);
      const e = Math.ceil(Math.random() * 20);
      this.data[0].x = [a, b, c, d, e];
      this.data[0].x.sort(function (a, b) {
        return a - b;
      });
      this.data[0].text = this.data[0].x;

      this.data[0].y = [];
      let i = 0;
      let lines = {};
      while (true) {
        let name = "Facillitie_" + Math.ceil(Math.random() * 9);
        if (lines[name] == null) {
          this.data[0].y.push(name);
          lines[name] = name;
        }
        if (this.data[0].y.length >= 5) {
          break;
        }
      }
      this.data = Object.assign([], this.data);
    },
    receiveSyncData(data) {
      console.log("receiveSyncData", data);
      this.receiveData = data;
    },
    changeData() {
      let data = { date: new Date(), message: "my sync data" };
      this.sendSyncData(data);
    },
  },
};
</script>

<style  lang="scss">
.eqp-alarm {
  .line-container {
    display: flex;
    .line-name {
      border-radius: 5px;
      background: cadetblue;
      padding: 0px 5px;
      color: white;
      height: 24px;
    }
  }

  .chart {
    height: calc(100% - 50px);
    overflow: hidden;
    width: 100%;
  }
  .plotly {
    width: 100%;
    height: 100%;
  }
}
</style>
