<template>
  <v-container class="param-trend">
    <div class="chart">
      <Plotly
        :id="id"
        ref="myChart"
        :data="data"
        :layout="layout"
        :display-mode-bar="false"
      />
    </div>
  </v-container>
</template>

<script>
import { Plotly } from "vue-plotly";
import FdcService from "@/services/FdcService";
import { uuid } from "vue-uuid";

export default {
  name: "ParamTrend",
  components: {
    Plotly,
  },
  data: function () {
    return {
      id: uuid.v4(),
      spec: {
        alarm: {
          highLimit: { low: -1, high: -3 },
          lowLimit: { high: -18, low: -16 },
        },
        warning: {
          highLimit: { high: -16, low: -14 },
          lowLimit: { low: 1, high: -1 },
        },
      },
      data: [
        {
          x: [
            new Date("2015-02-01  00:01:00"),
            new Date("2015-02-02  00:01:00"),
            new Date("2015-02-03  00:01:00"),
            new Date("2015-02-04  00:01:00"),
            new Date("2015-02-05  00:01:00"),
            new Date("2015-02-06  00:01:00"),
            new Date("2015-02-07  00:01:00"),
            new Date("2015-02-08  00:01:00"),
            new Date("2015-02-09  00:01:00"),
            new Date("2015-02-10  00:01:00"),
            new Date("2015-02-11  00:01:00"),
            new Date("2015-02-12  00:01:00"),
            new Date("2015-02-13  00:01:00"),
            new Date("2015-02-14  00:01:00"),
            new Date("2015-02-15  00:01:00"),
            new Date("2015-02-16  00:01:00"),
            new Date("2015-02-17  00:01:00"),
            new Date("2015-02-18  00:01:00"),
            new Date("2015-02-19  00:01:00"),
            new Date("2015-02-20  00:01:00"),
            new Date("2015-02-21  00:01:00"),
            new Date("2015-02-22  00:01:00"),
            new Date("2015-02-23  00:01:00"),
            new Date("2015-02-24  00:01:00"),
            new Date("2015-02-25  00:01:00"),
            new Date("2015-02-26  00:01:00"),
            new Date("2015-02-27  00:01:00"),
            new Date("2015-02-28  00:01:00"),
          ],
          y: [
            -14, -17, -8, -4, -7, -10, -12, -14, -12, -7, -11, -7, -18, -14,
            -14, -16, -13, -7, -8, -14, -8, -3, -9, -9, -4, -13, -9, -6,
          ],
          mode: "line",
          name: "temperature",
        },
      ],
      layout: {
        autosize: true,
        margin: {
          l: 50,
          r: 170,
          b: 100,
          t: 15,
          pad: 0,
        },
        // to highlight the timestamp we use shapes and create a rectangular

        shapes: [
          // 1st highlight during Feb 4 - Feb 6
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.8,
            y1: 1,
            x0: 0,
            x1: 1,
            fillcolor: "red",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.2,
            y1: 0,
            x0: 0,
            x1: 1,
            fillcolor: "red",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.6,
            y1: 0.8,
            x0: 0,
            x1: 1,
            fillcolor: "yellow",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
          {
            type: "rect",
            // x-reference is assigned to the x-values
            xref: "paper",
            // y-reference is assigned to the plot paper [0,1]
            yref: "paper",
            y0: 0.4,
            y1: 0.2,
            x0: 0,
            x1: 1,
            fillcolor: "yellow",
            opacity: 0.2,
            line: {
              width: 0,
            },
          },
        ],
        // height: 500,
        width: 500,
        showlegend: false,
        // legend:{
        //   x:0,y:1
        // },
        xaxis: {
          tickformat: "%y-%m-%d %H:%M:%S",
          tickangle: -45,
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const width = this.$refs.myChart.$el
        .getElementsByClassName("nsewdrag")[0]
        .getAttribute("width");
      const height = this.$refs.myChart.$el
        .getElementsByClassName("nsewdrag")[0]
        .getAttribute("height");
      FdcService.getChart(width, height, 100000).then((result) => {
        let xData = result.data.data.map((d) => d.date);
        let yData = result.data.data.map((d) => d.value);
        let xAVGData = result.data.avg.map((d) => d.date);
        let yAVGData = result.data.avg.map((d) => d.value);

        console.log(this.data);
        this.data = [];
        this.data.push({
          x: xData,
          y: yData,
          mode: "line",
          name: "trend",
          opacity: 0.5,
        });

        if (result.data.avg.length > 0) {
          this.data.push({
            x: xAVGData,
            y: yAVGData,
            mode: "line",
            name: "AVG",
          });
        }
        console.log(this.data);
        this.data = Object.assign([], this.data);
      });
    },
  },
};
</script>

<style  lang="scss">
.param-trend {
  .chart {
    //height: calc(100% - 50px);
    height: 100%;
    width: 100%;
  }
}
</style>
